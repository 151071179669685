import {useRouter} from 'next/router';
import dynamic from 'next/dynamic';
import {
  getIndividualPageData,
  getPageInfo,
  fetchAPIRequestData
} from "../api/global";
import {getFullListingInfo} from '../api/listing';
import {entitiesArrayDrupalSidenav} from "../utils/transforms";

import Custom403 from './403';
import {innerRequests} from "./blog/[category]/[...blog]";

const UserComponent = dynamic(() => import('../components/04_templates/User'));
const ListingNodeComponent = dynamic(() => import('../components/04_templates/ListingPage/ListingPage'));
const BoundaryNodeComponent = dynamic(() => import('../components/04_templates/BoundaryNode'));
const ArticleNodeComponent = dynamic(() => import('../components/04_templates/Article/Article'));
const DefaultNodeComponent = dynamic(() => import('../components/04_templates/DefaultNode'));
import ReactPlaceholder from 'react-placeholder';
// import "react-placeholder/lib/reactPlaceholder.css";

import {useListingsSolrSearch} from "../utils/hooks";

export async function getStaticProps(context) {
  const {params} = context;

  const page_path = Array.isArray(params?.page) ? params.page.join('/') : params?.page;
  if (page_path?.includes('sites/default/files')) {
    return {
      redirect: {
        destination: `https://cms.eatonrealty.com/${page_path}`,
        statusCode: 307,
      },
    }
  }
  let initialProps = {
    statusCode: 200,
  };

  let data, node_data = {}, termsMenu, recentNodes, agent_list, eaton_list,
    eaton_sold;

  try {
    if (page_path) {
      // Page data about currently opened path.
      data = await getPageInfo(`/${page_path}`);

      if (data?.statusCode == 404) {
        return {
          notFound: true,
        };
      }

      if (page_path?.includes('node/') && !data?.page?.resolved?.includes(`/node/`)) {
        // "data.page.resolved" will be like "https://cms.eatonrealty.com/some-path-alias"
        const new_path = data?.page?.resolved?.includes('.com')
          ? data.page.resolved.split('.com/').pop()
          : data.page.resolved.split('.site/').pop();
        return {
          redirect: {
            destination: `/${new_path}`,
            statusCode: 302,
          },
        };
      }

      // Check for any redirects:
      const regex1 = new RegExp('eatonrealty\.com|\.lndo\.site', 'ig');
      if (data?.page?.redirect && !regex1.test(data.page.redirect?.[0]?.to) && data?.page?.redirect?.length > 0) {
        // For setting GMUSER local storage later during rendering. Otherwise,
        // because of redirect it gets lost and we do not know if the Agent page was loaded from a redirect.
        let dest = data.page?.redirect[0]?.to;
        if (data?.page?.entity?.bundle == 'user' && data?.page?.redirect) {
          dest = dest + '?gt=1';
        }
        return {
          redirect: {
            destination: dest,
            statusCode: parseInt(data.page?.redirect[0]?.status),
          },
        }
      }

      if (data?.page?.entity) {
        if (data.page.entity.bundle == 'yard_sign') {
          node_data = await getIndividualPageData(data.page.jsonapi?.individual);
          // Redirect the page to the listing referenced
          if (node_data?.page_data?.listingRef?.length) {
            const new_path = data.page.resolved.includes('.com')
                ? data.page.resolved.split('.com/').pop()
                : data.page.resolved.split('.site/').pop();

            return {
              redirect: {
                destination: node_data.page_data.listingRef,
                statusCode: 307,
              },
            }
          }
          // Otherwise, redirect to the listings search page
          else {
            return {
              redirect: {
                destination: '/buy/homes-for-sale',
                statusCode: 307,
              },
            }
          }
        }
        else if (data.page.entity.bundle == 'listing') {
          node_data = await getFullListingInfo(data.page.entity.uuid);
        }
        else if (data.page.entity.bundle == 'article') {
          [termsMenu, recentNodes, node_data] = await innerRequests(data.page.jsonapi.individual);
        }
        else if (data.page.entity.bundle == 'user') {
          node_data = await getIndividualPageData(data.page.jsonapi.individual, {}, {agent: true});
          if (node_data) {
            // We only show profiles of AGENT users:
            if (node_data?.page_data?.roles?.length > 0 && !node_data.page_data.roles.includes('agent')) {
              return {
                notFound: true,
              }
            }

            if (node_data?.page_data?.roles?.length > 0 && node_data.page_data.roles.includes('agent')) {
              let listDefaultQuery = {
                'include': 'image,image.imageFile',
                'fields[listings]': 'id,internalId,broker,eaton,path,title,body,image,mlsNum,saleType,saleStatus,bathFull,bathHalf,bathAll,beds,coord,garage_space,price,dateAvail,sqft,lotSize',
                'fields[imagesMls]': 'imageFile',
                'fields[files]': 'uri',
                'filter[saleType][value]': 'Buy',
                'sort': '-price',
                'filter[isPublished][value]': 1,
                'page[limit]': 24,
                // 'page[offset]': 0,
              };

              let agentListingsQuery = {
                ...listDefaultQuery,
                'filter[user_ref.id][value]': node_data.page_data.id,
                'filter[saleStatus][condition][operator]': 'IN',
                'filter[saleStatus][condition][path]': 'saleStatus',
                'filter[saleStatus][condition][value][0]': 1,
                'filter[saleStatus][condition][value][1]': 2,
              };
              let eatonListingsQuery = {
                ...listDefaultQuery,
                'filter[eaton][value]': 1,
                'filter[saleStatus][condition][operator]': 'IN',
                'filter[saleStatus][condition][path]': 'saleStatus',
                'filter[saleStatus][condition][value][0]': 1,
                'filter[saleStatus][condition][value][1]': 2,
              };
              let eatonSoldQuery = {
                ...listDefaultQuery,
                'filter[eaton][value]': 1,
                'filter[saleStatus][value]': 3,
                'sort': '-changed',
                'page[limit]': 12,
              };
              [agent_list, eaton_list, eaton_sold] = await Promise.all([
                !node_data?.page_data?.hideList?.includes('active') ? fetchAPIRequestData(`/listings`, agentListingsQuery, {type: 'api'}) : Promise.resolve([]),
                !node_data?.page_data?.hideList?.includes('active') ? fetchAPIRequestData(`/listings`, eatonListingsQuery, {type: 'api'}) : Promise.resolve([]),
                !node_data?.page_data?.hideList?.includes('sold') ? fetchAPIRequestData(`/listings`, eatonSoldQuery, {type: 'api'}) : Promise.resolve([]),
              ]);
            }
          }
        }
        else {
          node_data = await getIndividualPageData(data.page.jsonapi.individual);
        }

        if (node_data) {
          initialProps = {...initialProps, ...node_data};
        }
      }

      // Merge initially defined props with response from the backend.
      initialProps = {...initialProps, ...data};
    }

    if (!data) {
      return {
        notFound: true,
      }
    }
  }
  catch (e) {
    console.warn('Aliased page retrieval error:', e);
    // Pass status code as internal properly. It is being checked inside of
    // render() method of _app.js.
    initialProps.statusCode = 500;

    // In case of Server Side Rendering, we want the server to throw the
    // correct error code.
    // if (res) {
    //   res.statusCode = 500;
    // }
  }

  let returnProps = {
    props: {
      ...initialProps,
    },
    revalidate: 900, // @todo: change for production back to 14400
    // notFound: true, // https://github.com/vercel/next.js/discussions/11862,
    // https://stackoverflow.com/questions/67946219/throw-new-errorfailed-to-load-static-props-when-setting-fallback-true-i/67946220#67946220
  };

  if (data?.page?.entity?.bundle == 'article') {
    returnProps.props = {
      ...returnProps.props,
      termsMenu: (termsMenu?.page_data || []),
      recentNodes: (recentNodes?.page_data || [])
    }
  }

  if (data?.page?.entity?.bundle == 'user') {
    returnProps.props = {
      ...returnProps.props,
      agentList: (agent_list?.page_data || []),
      eatonList: (eaton_list?.page_data || []),
      eatonSold: (eaton_sold?.page_data || []),
    }
  }

  return returnProps;
}

export async function getStaticPaths() {
  const paths = [];
  // We'll pre-render only these paths at build time.
  // { fallback: false } means other routes should 404.
  return {paths, fallback: true}
}

function AliasedPage(props) {
  // @see 404 fallback:
  // https://github.com/vercel/next.js/discussions/10960#discussioncomment-22365
  const router = useRouter();
  if (router.isFallback) {
    return (
      // <main>
      <div className='container mx-auto'>
        <div>Loading...</div>
      </div>
      // </main>
    );
  }
  if (!props || !props?.page) {
    return (<Custom403/>);
  }

  let node_data, node, meta, terms, boundaries;

  if (props) {
    node = props?.page?.entity || null;
    meta = props?.metatags?.tags || [];

    if (node && props.page_data) {
      node_data = props.page_data;
      let city = node_data.cityTerm

      if (node.bundle == 'user' && router.query?.gt) {
        node_data.setGmuser = router.query?.gt == '1' ? true : false;
        if (Array.isArray(router.query?.page)) {
          router.replace('/' + router.query?.page.join('/'), undefined, { shallow: true });
        }
      }

      if (node.bundle == 'listing' && node_data?.coord?.value) {
        let fetchBoundary = {
          solrBoundaryPages: true,
          geo_boundary: node_data.coord.value,
        };
        if (node_data.cityTerm) {
          fetchBoundary = {
            ...fetchBoundary,
            city: ((Array.isArray(node_data.cityTerm)) ? node_data.cityTerm.map(el => el.internalId) : [node_data.cityTerm?.internalId])
          }
        }
        boundaries = useListingsSolrSearch(fetchBoundary);
      }
    }
    // For Blog pages - list of categories:
    if (props.termsMenu) {
      terms = entitiesArrayDrupalSidenav(props.termsMenu);
    }
  }

  return (
    <div style={{minHeight: 1000}}>
      <ReactPlaceholder type='text' rows={40} ready={node_data?.internalId}
                        showLoadingAnimation={true}>
        {node && node_data &&
        <div className={`node node-type-${props?.page?.entity?.bundle} node-${props?.page?.entity?.id}`}>
          {(node_data && node.bundle == 'listing') &&
            <ListingNodeComponent node={node_data}
                                boundary={boundaries?.data?.page_data}
                                metatags={meta}/>
          }
          {(node_data && node.bundle == 'user') &&
          <UserComponent node={node_data} eaton_list={props.eatonList}
                         eaton_sold={props.eatonSold}
                         agent_list={props.agentList} metatags={meta}/>
          }
          {(node_data && node.bundle == 'boundary_page') &&
          <BoundaryNodeComponent node={node_data} metatags={meta}/>
          }
          {(node_data && node.bundle == 'article') &&
          <ArticleNodeComponent node={node_data} metatags={meta}
                                terms_menu={terms || []}
                                recent={props.recentNodes || []}/>
          }
          {(node_data && !['article', 'boundary_page', 'listing', 'user'].includes(node.bundle)) &&
          <DefaultNodeComponent node={node_data} metatags={meta}/>
          }
        </div>
        }
        {(!node || !node_data) && props?.page?.label &&
        <div>
          <h1>{props.page.label}</h1>
          <p>Hmmm... Looks like something is missing here. We will update it
            soon.</p>
        </div>
        }
      </ReactPlaceholder>
    </div>
  );
}

export default AliasedPage;
